export function getContacts(params = {}) {
  return window.$axios.get(`${window.service.api}/customer/contacts`, { params });
}

export function create(data) {
  return window.$axios.post(`${window.service.api}/customer/contact_create`, data);
}

export function update(data) {
  return window.$axios.put(`${window.service.api}/customer/contact_update`, data);
}

export function contact_delete(id) {
  return window.$axios.delete(`${window.service.api}/customer/contact_delete?id=${id}`);
}

export function create_v2(data) {
  return window.$axios.post(`${window.service.api}/customer/contact_create_v2`, data);
}
